import React, { useMemo } from 'react';

import { getFirstImageFromContent } from '@/utility/lexicalContentHelper';
import { t } from '@/utility/localization';

import FeedContent from '@/features/Feed/pages/PostPublicPage/FeedContent';
import PostAuthorData from '@/features/Feed/pages/PostPublicPage/PostAuthorData';

import NextImage from '@/components/common/NextImage';
import NplToolTip from '@/components/common/NplToolTip';
import Icon from '@/components/npl/Icon';
import NPLBadge from '@/components/npl/NPLBadge';
import NPLButton from '@/components/npl/NPLButton';
import NPLIconButton from '@/components/npl/NPLIconButton';

import OptionMenuV2 from '@/pages/portal/products/components/OptionMenuV2';
import {
  VariableTypes,
  replaceVariables
} from '@/pages/portal/utils/common';

import { EntityPostCard } from '../EntityPostCard';
import { LockedBadge } from '../LockedBadge';
import { COMMUNITY_POSTS_STATUS } from '../constants';

const PostCardContent = ({
  announcement,
  communityName,
  onEdit,
  onDelete,
  singlePost,
  onPin,
  isLocked,
  onUnpin,
  showMenu = true,
  handleReport,
  fullView,
  onLockButtonClick,
  setAnnouncementToShare,
  showPostVisibilityType = true,
  user,
  showStatus,
  isManager = false,
  onSelectedMemberDetails,
  communityLink
}) => {
  const {
    _id: postId,
    title,
    content,
    isPinned,
    author,
    visibilityType,
    mentionedProducts,
    status,
    blurImageUrl
  } = announcement || {};

  const isAuthor = user?._id === author?._id;
  const mentionedProduct = {
    ...mentionedProducts?.[0]?.productInfo,
    type: mentionedProducts?.[0]?.type
  };
  const locked = isLocked && visibilityType === 'members';

  const isPinnedPreview = isPinned && !singlePost;
  const isPending = status === COMMUNITY_POSTS_STATUS.PENDING;
  const isRejected = status === COMMUNITY_POSTS_STATUS.REJECTED;
  const isApproved = status === COMMUNITY_POSTS_STATUS.APPROVED;

  const editorVariables = [
    {
      text: communityName,
      variable: VariableTypes.CommunityName
    },
    {
      text: 'User',
      variable: VariableTypes.MemberName
    },
    {
      text: `${author?.firstName} ${author?.lastName}`,
      variable: VariableTypes.CommunityManagerName
    }
  ];

  const handleDelete = (e) => {
    e?.stopPropagation();
    onDelete(postId);
  };

  const handleEdit = (e) => {
    e?.stopPropagation();
    onEdit(announcement);
  };

  const handlePin = (e) => {
    e?.stopPropagation();
    if (isPinned) {
      onUnpin(postId);
    } else {
      onPin(postId);
    }
  };

  const getProfileImageFormatted = (url) => {
    return {
      alt: 'profile image',
      mobileImgProps: {
        src: url,
        width: 40,
        height: 40,
        quality: 50,
        objectFit: 'cover'
      }
    };
  };

  const announcementKebabOptions = useMemo(() => {
    const options = [
      {
        label: t('edit'),
        onClick: handleEdit,
        icon: 'edit',
        visibility: isAuthor
      },
      {
        isDivider: true,
        visibility: isAuthor && status === COMMUNITY_POSTS_STATUS.APPROVED
      },
      {
        label: t('share'),
        onClick: () => setAnnouncementToShare(announcement),
        icon: 'share-01',
        visibility:
          announcement?.status === COMMUNITY_POSTS_STATUS.APPROVED
      },
      {
        label: isPinned ? t('unpin') : t('pin'),
        onClick: handlePin,
        icon: isPinned ? 'unpin' : 'pin',
        visibility: isManager && isApproved
      },
      // {
      //   label: hideLikeCount ? t('show-like-count') : t('hide-like-count'),
      //   onClick: () => handleHideLinkCount(postId, !hideLikeCount),
      //   icon: hideLikeCount ? 'heart' : 'heart-hide',
      //   visibility: isAuthor && isManager
      // },
      // {
      //   label: hideImpressions
      //     ? t('show-view-count')
      //     : t('hide-view-count'),
      //   onClick: () => handleHideViewCount(postId, !hideImpressions),
      //   icon: hideImpressions ? 'eye' : 'eye-off',
      //   visibility: isAuthor && isManager
      // },
      {
        isDivider: true,
        visibility: isApproved
      },
      {
        label: t('report'),
        onClick: () => handleReport(postId),
        icon: 'info-circle',
        isDanger: true,
        visibility: !isAuthor && !isManager
      },
      {
        label: t('delete'),
        onClick: handleDelete,
        icon: 'trash-01',
        isDanger: true,
        visibility: isAuthor || isManager
      }
    ];

    return options?.filter((option) => option.visibility);
  }, [
    handleEdit,
    isAuthor,
    status,
    announcement,
    isPinned,
    handlePin,
    isManager,
    handleDelete,
    setAnnouncementToShare,
    handleReport,
    postId
  ]);

  const firstImageInContent = useMemo(
    () => getFirstImageFromContent(content),
    [content]
  );

  return (
    <div
      className="c-AnnouncementCard relative bg-white-default 
      px-16 pt-16 group-hover:cursor-pointer 
    group-hover:border-npl-neutral-light-solid-4 group-hover:bg-npl-neutral-light-solid-2">
      {showStatus && isRejected ? (
        <div className="absolute top-16 right-16 flex flex-row gap-8 items-center">
          <NPLButton
            hierarchy="neutral_secondary"
            buttonText={t('edit')}
            size="sm"
            onClick={handleEdit}
          />
          <NPLIconButton
            onClick={handleDelete}
            icon="trash-01"
            size="sm"
            hierarchy="plain"
          />
        </div>
      ) : locked ? (
        <div className="absolute top-16 right-16">
          <LockedBadge />
        </div>
      ) : (
        showMenu && (
          <div className="absolute top-16 right-16">
            <OptionMenuV2
              options={[...announcementKebabOptions]}
              renderCustomButton={(onClick) => (
                <NPLButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isLocked || !user) {
                      onLockButtonClick();
                      return;
                    }
                    onClick();
                  }}
                  rounded
                  leadIcon="dots-horizontal"
                  size="sm"
                  hierarchy="plain"
                />
              )}
            />
          </div>
        )
      )}
      {isPinned && (
        <div className="mb-8 pl-16 flex flex-row items-center gap-8">
          <Icon
            name="pin-filled"
            width={16}
            height={16}
            fill="#1B1B1859"
          />
          <p className="text-label-md font-medium text-npl-text-tertiary-on-light">
            {t('pinned')}
          </p>
        </div>
      )}
      {showStatus && isPending && (
        <div className="w-fit mb-16">
          <NplToolTip text={t('under-review')}>
            <NPLBadge
              text={t('pending-approval')}
              type="neutral"
              size="sm"
            />
          </NplToolTip>
        </div>
      )}
      {showStatus && isRejected && (
        <div className="w-fit mb-16">
          <NPLBadge text={t('post-rejected')} type="danger" size="sm" />
        </div>
      )}
      <div
        className={`flex ${isPinnedPreview ? 'flex-row' : 'flex-col'} gap-8`}>
        <div>
          {isPinnedPreview ? (
            <div className="h-32 w-32 overflow-hidden rounded-full">
              <NextImage
                {...getProfileImageFormatted(author?.profileImage)}
                className="h-32 w-32 rounded-full"
              />
            </div>
          ) : (
            <PostAuthorData
              post={announcement}
              showPostVisibilityType={showPostVisibilityType}
              onSelectedMemberDetails={onSelectedMemberDetails}
            />
          )}
        </div>
        <div className="flex flex-col gap-8">
          <div className="text-heading-sm font-semibold break-words">
            {replaceVariables(title, editorVariables)}
          </div>
          <FeedContent
            content={content}
            isUnlockedView={!locked}
            previewContent={isPinnedPreview}
            feedCoverImageUrl={firstImageInContent || blurImageUrl}
            onLockButtonClick={onLockButtonClick}
            fullView={fullView}
            editorVariables={editorVariables}
          />
        </div>
        {!isPinnedPreview && mentionedProduct && (
          <EntityPostCard
            entity={mentionedProduct}
            communityLink={communityLink}
          />
        )}
      </div>
    </div>
  );
};

export default PostCardContent;
