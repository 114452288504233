import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getDescriptionFromContent } from '@/utility/lexicalContentHelper';
import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Editor from '@/components/common/Editor/Editor';
import NextImage from '@/components/common/NextImage';

import { replaceVariables } from '@/pages/portal/utils/common';

import BlurImageWithLock from '../../components/BlurImageWithLock';
import { useImageLoader } from '../../components/useImageLoader';

type Props = {
  content: object;
  editorVariables: object;
  feedCoverImageUrl: string;
  isPendingApproval: boolean;
  isStaticPost: boolean;
  isUnlockedView: boolean;
  onLockButtonClick: () => void;
  fullView?: boolean;
  previewContent?: boolean;
};

export default function FeedContent({
  content,
  isUnlockedView,
  feedCoverImageUrl,
  onLockButtonClick,
  editorVariables,
  fullView = false,
  isPendingApproval,
  previewContent = false
}: Props) {
  const { isGtEqMd } = useWindowWidthContext();
  const { dimensions, isLoading } = useImageLoader(feedCoverImageUrl);
  const paragraphRef = useRef<HTMLDivElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const firstParagraphInContent = useMemo(
    () => getDescriptionFromContent(content),
    [content]
  );

  useEffect(() => {
    const checkTextTruncation = () => {
      if (paragraphRef.current) {
        const { scrollHeight, clientHeight } = paragraphRef.current;
        setIsTextTruncated(scrollHeight > clientHeight);
      }
    };

    checkTextTruncation();
    window.addEventListener('resize', checkTextTruncation);

    return () => {
      window.removeEventListener('resize', checkTextTruncation);
    };
  }, [firstParagraphInContent, isGtEqMd]);

  const renderImage = () => {
    if (isLoading) {
      return (
        <div
          className={`animate-pulse rounded-12 bg-npl-neutral-light-solid-4`}
        />
      );
    }

    if (isUnlockedView) {
      return (
        <NextImage
          className="rounded-12"
          mobileImgProps={{
            src: feedCoverImageUrl,
            width: dimensions.width,
            height: dimensions.height,
            objectFit: 'cover',
            quality: 50
          }}
        />
      );
    }

    return (
      <BlurImageWithLock
        imageUrl={feedCoverImageUrl}
        onClick={onLockButtonClick}
        isPendingApproval={isPendingApproval}
      />
    );
  };

  return (
    <div>
      {fullView ? (
        <Editor
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          existingContent={content as any}
          editable={false}
          placeholderText={t('loading')}
          isAutoFocus={false}
          imageIdentity={null}
          customContentClasses="w-full outline-none text-dark-1b text-para-md editor-input caret-neutral-10"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          variables={editorVariables as any}
        />
      ) : (
        (feedCoverImageUrl || firstParagraphInContent) && (
          <div
            className={classNames({
              'pb-16': previewContent && !firstParagraphInContent
            })}>
            {firstParagraphInContent && (
              <div className="mb-16">
                <div
                  ref={paragraphRef}
                  className={classNames('relative text-para-sm', {
                    'line-clamp-2': previewContent || isGtEqMd,
                    'line-clamp-4': !previewContent && !isGtEqMd,
                    'text-npl-text-secondary-on-light': previewContent,
                    'text-npl-text-icon-on-light-surface-primary':
                      !previewContent
                  })}>
                  {firstParagraphInContent &&
                    replaceVariables(
                      firstParagraphInContent,
                      editorVariables
                    )}
                  {isTextTruncated && !previewContent && (
                    <div
                      onClick={onLockButtonClick}
                      className="absolute bottom-0 right-0 z-[1] cursor-pointer bg-white-default px-4 font-semibold text-npl-text-icon-on-light-surface-tertiary group-hover:bg-npl-neutral-light-solid-2">
                      ...{t('see-more')}
                    </div>
                  )}
                </div>
              </div>
            )}

            {feedCoverImageUrl && !previewContent && renderImage()}
          </div>
        )
      )}
    </div>
  );
}
