import { socialMediaLinks } from './constants';

export const getSocialMediaLink = (socialUsername, socialMedia) => {
  return `${socialMediaLinks[socialMedia]}${socialUsername}`;
};

export const getSocialMediaUsername = (socialMediaLink) => {
  if (socialMediaLink?.includes('@')) {
    return socialMediaLink?.split('@').pop();
  }
  const splittedLink = socialMediaLink?.split('/');

  const lastValueInLink = splittedLink?.pop();

  if (!lastValueInLink) return splittedLink?.[splittedLink?.length - 1];

  return lastValueInLink;
};
