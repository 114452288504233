import useSWR from 'swr';

import config from '@/utility/config';

import { axiosFetcher } from '@/services/helpers/swr';

export default function useCommunityEntity(communityId) {
  const apiPath = `${config?.communityApiBasePath}/api/v1/community-landing-page/${communityId}/dynamic-data`;

  const { data, isLoading } = useSWR(apiPath, axiosFetcher);
  const isFetchingEntities = !data || isLoading;

  const upcomingEntities = data?.data?.upcomingItems ?? [];
  const ongoingEntities = data?.data?.ongoingItems ?? [];

  return {
    upcomingEntities,
    ongoingEntities,
    isFetchingEntities,
    error: data?.error
  };
}
