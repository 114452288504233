import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import { LockedBadge } from '@/features/Announcements/LockedBadge';
import { trackCommLandingPageUnlockPostClick } from '@/features/Feed/analytics';
import BlurImageWithLock from '@/features/Feed/components/BlurImageWithLock';
import useFeedAnalytics from '@/features/Feed/hooks/useFeedAnalytics';
import PostAuthorData from '@/features/Feed/pages/PostPublicPage/PostAuthorData';
import PostContent from '@/features/Feed/pages/PostPublicPage/PostContent';
import {
  VISIBILITY_TYPE_PUBLIC,
  getPostShareUrlWithCommunityLink
} from '@/features/Feed/utils';

import Icon from '@/components/npl/Icon';

import {
  VariableTypes,
  replaceVariables
} from '@/pages/portal/utils/common';

import { formatCompactCount } from '../utils';

const FeedCard = ({
  post,
  communityLink,
  isCommunityMember,
  isPendingApproval,
  onJoinCommunityClick,
  communityName,
  showPreview,
  hideViewCount
}) => {
  const {
    visibilityType,
    _id: postId,
    title,
    content,
    blurImageUrl,
    isPinned,
    totalImpressions,
    author,
    isStaticPost
  } = post || {};

  const feedPublicPageLink = isStaticPost
    ? ''
    : getPostShareUrlWithCommunityLink(post, communityLink, true);
  const canUserViewPost =
    isCommunityMember || visibilityType === VISIBILITY_TYPE_PUBLIC;

  const { feedCardRef } = useFeedAnalytics({ postId, canUserViewPost });

  const viewCount = totalImpressions || 1;

  const editorVariables = [
    {
      text: communityName,
      variable: VariableTypes.CommunityName
    },
    {
      text: 'User',
      variable: VariableTypes.MemberName
    },
    {
      text: `${author?.firstName} ${author?.lastName}`,
      variable: VariableTypes.CommunityManagerName
    }
  ];

  const renderImpression = () => {
    return (
      viewCount > 0 &&
      !hideViewCount && (
        <div className="flex flex-row items-center gap-4">
          <Icon name="eye" height={20} width={20} fill="#1B1B1899" />
          <span className="text-label-sm font-medium text-npl-text-icon-on-light-surface-secondary">
            {formatCompactCount(viewCount)}
          </span>
        </div>
      )
    );
  };

  const handlePostClick = () => {
    if (canUserViewPost) {
      showPreview?.(post);
    } else {
      onJoinCommunityClick(post);
      trackCommLandingPageUnlockPostClick({
        communityId: post?.communities?.[0],
        postId
      });
    }
  };

  const renderLockedView = () => {
    if (isStaticPost) {
      return (
        <>
          <div className="flex justify-between">
            <PostAuthorData post={post} showPostVisibilityType={false} />
          </div>
          <div className="mt-20">
            <div className="text-heading-sm font-semibold">{title}</div>
            <div className="mt-16">
              <BlurImageWithLock
                imageUrl={blurImageUrl}
                onClick={() => onJoinCommunityClick(post)}
                isPendingApproval={isPendingApproval}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="flex justify-between">
          <div rel="noreferrer">
            <PostAuthorData post={post} showPostVisibilityType={false} />
          </div>
          {renderImpression()}
        </div>
        <div className="mt-20">
          <div className="text-heading-xs font-semibold break-words">
            {replaceVariables(title, editorVariables)}
          </div>
          <div className="mt-24">
            <LockedBadge />
          </div>
        </div>
      </>
    );
  };

  const renderUnlockedView = () => {
    if (isStaticPost) {
      return (
        <>
          <div className="flex justify-between">
            <PostAuthorData post={post} showPostVisibilityType={false} />
            {renderImpression()}
          </div>
          <div className="mt-20">
            <div className="text-heading-sm font-semibold">{title}</div>
            <div className="mt-16">
              <PostContent
                postId={postId}
                content={content}
                // community={community}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="flex justify-between items-center">
          <Link href={feedPublicPageLink} rel="noreferrer">
            <PostAuthorData post={post} showPostVisibilityType={false} />
          </Link>
          {renderImpression()}
        </div>
        <div className="mt-20">
          <div className="text-heading-xs font-semibold">
            {replaceVariables(title, editorVariables)}
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      ref={feedCardRef}
      onClick={handlePostClick}
      className={classNames(
        'c-FeedCard group relative rounded-12 overflow-hidden bg-white-default p-16 shadow-md transition-all',
        {
          'hover:cursor-pointer hover:border-npl-neutral-light-solid-4 hover:bg-npl-neutral-light-solid-2':
            !isStaticPost
        }
      )}>
      {isPinned && (
        <div className="mb-4 absolute top-0 left-0 bg-npl-neutral-light-alpha-3 rounded-br-12 p-4">
          <Icon
            name="pin-filled"
            width={12}
            height={12}
            fill="#1B1B1880"
          />
        </div>
      )}
      {canUserViewPost ? renderUnlockedView() : renderLockedView()}
    </div>
  );
};

export default FeedCard;
