import Link from 'next/link';
import React from 'react';

import { trackGAEvent } from '@/utility/analytics';
import {
  ONGOING_CARD_CLICK,
  UPCOMING_CARD_CLICK
} from '@/utility/analyticsConsts';
import {
  getFormattedStartEndDate,
  getGMTOffsetFromTimezoneId
} from '@/utility/dateHelper';
import { getEventTicketText } from '@/utility/eventUtils';
import { ENTITY_SLUGS_PREFIX, formatNumber } from '@/utility/helpers';
import { checkHideEventAttendees } from '@/utility/hideEventAttendeeHelper';
import { formatDateTimeWithLocale, t } from '@/utility/localization';
import { getEntityPublicPageUrl } from '@/utility/routesHelper';

import CalendarDayBox from '@/components/common/CalendarDayBox';
import NextImage from '@/components/common/NextImage';
import Icon from '@/components/npl/Icon';
import NPLBadge, { NPL_BADGE_HIERARCHY } from '@/components/npl/NPLBadge';

import { ENTITY_TYPE } from '@/pages/checkout-global/consts';
import { SESSION_1_ON_1_ONLINE } from '@/pages/portal/products/constants';
import { getPriceText } from '@/pages/portal/utils';

import { ENTITY_STATUS } from '../../constants';

function EntityCard({
  entity,
  communityLink,
  genericTrackingFields,
  entityStatus,
  communityConfig
}) {
  const {
    _id: entityId,
    // GENERIC
    itemType,
    startTime,
    endTime,
    title,
    currency,
    amount,
    pricingConfig,
    type,
    timezoneId,

    // EVENTS
    bannerImg,
    attendeeLimit,
    isSoldout,
    registered,
    isCapacitySet,
    slug,
    attendees,
    isRegistrationClosed,
    goingAttendees,

    //CHALLENGE
    cover,
    participantCount,
    durationInDays,
    joined,
    additionalSettings,

    // SESSION
    sessionObjectId,
    sessionStartTime,
    timezoneOfAttendee,
    hostLearnerObjectId
  } = entity || {};

  const entityPrice = amount ?? pricingConfig?.amount;
  const entityCurrency = currency ?? pricingConfig?.currency;

  const priceText = getPriceText(
    entityPrice,
    null,
    entityCurrency,
    pricingConfig?.priceType
  );

  const coverBannerImg = bannerImg ?? cover ?? sessionObjectId?.thumbnail;

  const eventHideAttendeeConfig = entity?.config?.hideEventAttendeeCount;

  const hideAttendeesSection =
    attendees === 0 ||
    checkHideEventAttendees(
      communityConfig?.hideEventAttendeeCount,
      eventHideAttendeeConfig
    );

  const getCardDetails = () => {
    switch (itemType) {
      case ENTITY_TYPE.EVENT:
        const eventDetails = [
          // Event time + timezone
          {
            icon: 'clock',
            title: `${formatDateTimeWithLocale(
              new Date(startTime),
              'hh:mm a'
            )} ${getGMTOffsetFromTimezoneId(timezoneId)}`
          },
          // Event location
          {
            icon: 'globe-02',
            title: type === 'live' ? t('online') : t('in-person')
          }
        ];

        if (attendees > 0 && !hideAttendeesSection) {
          eventDetails.push(
            // Attendee count
            {
              icon: 'users-01',
              title: `${formatNumber(attendees)} ${t('attending')}`
            }
          );
        }

        return eventDetails;
      case ENTITY_TYPE.CHALLENGE:
        const challengeDetails = [];

        if (durationInDays) {
          challengeDetails.push({
            icon: 'clock',
            title: `${durationInDays ?? 0} ${t('days')}`
          });
        }

        if (startTime) {
          challengeDetails.push({
            icon: 'calendar-range-01',
            title: getFormattedStartEndDate({
              startDate: startTime,
              endDate: endTime
            })
          });
        }

        if (
          participantCount > 0 &&
          !additionalSettings?.hideParticipantCountOnLP
        ) {
          challengeDetails.push(
            // Attendee count
            {
              icon: 'users-01',
              title: `${formatNumber(participantCount)} ${t(
                'participants'
              )}`
            }
          );
        }

        return challengeDetails;
      case ENTITY_TYPE.SESSION:
        const { firstName, lastName } = hostLearnerObjectId || {};
        return [
          {
            icon: 'clock',
            title: `${formatDateTimeWithLocale(
              new Date(sessionStartTime),
              'hh:mm a'
            )} ${getGMTOffsetFromTimezoneId(timezoneOfAttendee)}`
          },
          {
            icon: 'globe-02',
            title:
              sessionObjectId?.location?.type === SESSION_1_ON_1_ONLINE
                ? t('online')
                : t('in-person')
          },
          {
            image: hostLearnerObjectId?.profileImage,
            title: t('with-host', { hostName: `${firstName} ${lastName}` })
          }
        ];
      default:
        return [];
    }
  };

  const getEntityIcon = () => {
    switch (itemType) {
      case ENTITY_TYPE.EVENT:
        return 'calendar';
      case ENTITY_TYPE.CHALLENGE:
        return 'trophy-01';
      case ENTITY_TYPE.SESSION:
        return '1-1-session';
    }
  };

  const getTagsToShow = () => {
    switch (itemType) {
      case ENTITY_TYPE.EVENT:
        // Attending
        if (registered) {
          return (
            <NPLBadge
              type={NPL_BADGE_HIERARCHY.SUCCESS}
              text={t('attending-0')}
            />
          );
        }
        const ticketsLeft = attendeeLimit - goingAttendees;

        // Sold out / Registration Closed
        if (
          isSoldout ||
          isRegistrationClosed ||
          (isCapacitySet && ticketsLeft <= 0)
        ) {
          return (
            <NPLBadge
              type={NPL_BADGE_HIERARCHY.DANGER}
              text={t('sold-out')}
            />
          );
        }
        // Default
        return (
          <div className="flex gap-8">
            <NPLBadge
              text={priceText}
              type={
                priceText === t('free')
                  ? NPL_BADGE_HIERARCHY.OUTLINE
                  : NPL_BADGE_HIERARCHY.INFO
              }
            />
            {isCapacitySet && attendeeLimit > 0 && (
              <NPLBadge
                text={getEventTicketText(t, ticketsLeft)}
                type={NPL_BADGE_HIERARCHY.ACCENT}
              />
            )}
          </div>
        );
      case ENTITY_TYPE.CHALLENGE:
        if (joined) {
          return (
            <NPLBadge
              type={NPL_BADGE_HIERARCHY.SUCCESS}
              text={t('joined')}
            />
          );
        }
        return (
          <NPLBadge
            text={priceText}
            type={
              priceText === t('free')
                ? NPL_BADGE_HIERARCHY.OUTLINE
                : NPL_BADGE_HIERARCHY.INFO
            }
          />
        );
      case ENTITY_TYPE.SESSION:
        return (
          <NPLBadge
            text={t('booked')}
            type={NPL_BADGE_HIERARCHY.SUCCESS}
          />
        );
    }
  };

  const getEntityPrefix = () => {
    switch (itemType) {
      case ENTITY_TYPE.EVENT:
        return ENTITY_SLUGS_PREFIX.EVENT;
      case ENTITY_TYPE.CHALLENGE:
        return ENTITY_SLUGS_PREFIX.CHALLENGES;
      case ENTITY_TYPE.SESSION:
        return ENTITY_SLUGS_PREFIX.PRODUCTS;
    }
  };

  const getCardCTA = () => {
    return getEntityPublicPageUrl({
      communitySlug: communityLink,
      entityType: getEntityPrefix(),
      entitySlug: slug ?? sessionObjectId?.resourceSlug,
      fullUrl: false
    });
  };

  const onCardClick = () => {
    const gaEvent =
      entityStatus === ENTITY_STATUS?.UPCOMING
        ? UPCOMING_CARD_CLICK
        : ONGOING_CARD_CLICK;

    trackGAEvent(gaEvent, {
      ...genericTrackingFields,
      entityType: getEntityPrefix(),
      productType: getEntityPrefix(),
      productId: entityId,
      entityId: entityId
    });
  };

  return (
    <Link
      className="c-EntityCard overflow-hidden relative w-full rounded-16 border-1 border-npl-neutral-light-solid-6 bg-white-default shadow-npl-styles-button-shadow transition-all hover:cursor-pointer hover:border-npl-neutral-light-solid-8 hover:bg-npl-neutral-light-solid-2"
      href={getCardCTA()}
      onClick={onCardClick}>
      <div className="absolute left-8 top-8 z-1 flex rounded-4 bg-white-default">
        <div className="flex gap-8 px-8 py-4">
          <Icon
            name={getEntityIcon()}
            width={16}
            height={16}
            fill="#1B1B1880"
          />
          <div className="text-overline-sm font-medium uppercase text-npl-text-icon-on-light-surface-secondary">
            {t(itemType)}
          </div>
        </div>
      </div>
      <NextImage
        className="aspect-h-1 aspect-w-2 bg-clip-content"
        mobileImgProps={{
          src: coverBannerImg,
          layout: 'fill',
          objectFit: 'cover',
          priority: true,
          quality: 50
        }}
      />

      <div className="flex flex-col gap-12 p-16 min-h-[190px]">
        <div className="z-1 mt-[-32px]">
          <CalendarDayBox date={startTime} dark />
        </div>
        <div className="line-clamp-2 break-words text-heading-sm font-semibold">
          {title ?? sessionObjectId?.title}
        </div>
        <div className="flex flex-col gap-8">
          {getCardDetails().map((details, index) => {
            return (
              <div className="flex h-16 gap-8" key={index}>
                {details?.image ? (
                  <NextImage
                    className="rounded-full"
                    mobileImgProps={{
                      src: details?.image,
                      width: 16,
                      height: 16,
                      objectFit: 'cover',
                      priority: true,
                      quality: 25
                    }}
                  />
                ) : (
                  <Icon
                    name={details.icon}
                    width={16}
                    height={16}
                    fill="#1B1B1880"
                  />
                )}

                <div className="text-label-md text-npl-text-icon-on-light-surface-secondary">
                  {details.title}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex gap-4 mt-auto">{getTagsToShow()}</div>
      </div>
    </Link>
  );
}

export default EntityCard;
