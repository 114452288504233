import { t } from '@/utility/localization';

import Icon from '@/components/npl/Icon';

export const LockedBadge = () => {
  return (
    <div className="flex flex-row text-label-sm font-medium text-npl-text-secondary-on-light w-fit p-4 rounded-[6px] border-1 border-npl-neutral-light-alpha-6 gap-4 items-center">
      <Icon name="lock-01" width={16} height={16} fill="#1B1B18A6" />
      {t('locked')}
    </div>
  );
};
