import { t } from '@/utility/localization';

import NPLButton from '@/components/npl/NPLButton';

export const ApprovalOptions = ({
  announcement,
  onReject,
  onApprove,
  loading,
  successCallBack
}) => {
  return (
    <div className="pb-8 px-16 pt-16 flex flex-row gap-8 w-full">
      <NPLButton
        buttonText={t('reject')}
        size="md"
        stretch
        leadIcon="x-close"
        hierarchy="destructive_secondary"
        loading={loading}
        onClick={(event) => {
          event?.preventDefault();
          event?.stopPropagation();
          onReject(announcement, successCallBack);
        }}
      />
      <NPLButton
        buttonText={t('approve')}
        size="md"
        stretch
        hierarchy="success_secondary"
        leadIcon="check"
        loading={loading}
        onClick={(event) => {
          event?.preventDefault();
          event?.stopPropagation();
          onApprove(announcement, successCallBack);
        }}
      />
    </div>
  );
};
