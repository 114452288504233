import { STATIC_ASSET_BASE_URL } from '@/utility/constants';

export const ANNOUNCEMENTS_PAGE_TITLE = 'Connect with your audience';
export const ANNOUNCEMENTS_PAGE_SUBTITLE =
  'Send a message or an annoucement';
export const ANNOUNCEMENTS_TITLE = 'Announcements';

export const modalImage = {
  alt: 'Success tick',
  mobileImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/success-tick-announcements.png`,
    width: 80,
    height: 80
  }
};

export const trainerProfilePlaceholder = {
  firstName: 'John',
  lastName: 'Doe',
  profileImageProps: {
    alt: 'profile image',
    desktopImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    },
    mobileImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    }
  }
};

export const subPageTypes = {
  SETTINGS: 'settings',
  APPROVALS: 'approvals'
};

export const COMMUNITY_POSTS_POSTED_BY = {
  ALL: 'ALL',
  MEMBER: 'MEMBER',
  CREATOR: 'CREATOR'
};

export const COMMUNITY_POSTS_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECTED: 'rejected'
};
