import { useEffect, useRef, useState } from 'react';

import { scrollToRef } from '@/utility/domHelpers';

import Modal from '@/components/common/Modal';

import usePostReaction from '@/hooks/usePostReaction';

import CommentSection from '../CommentSection/CommentSection';
import { trackPostViewed } from '../Feed/analytics';
import PostActionBtnsRow from '../Feed/pages/PostPublicPage/PostActionBtnsRow';
import { ApprovalOptions } from './ApprovalCard/ApprovalOptions';
import PostCardContent from './PostCardContent/PostCardContent';
import { COMMUNITY_POSTS_STATUS } from './constants';

const AnnouncementPreviewModal = ({
  post,
  community,
  showPreviewModal,
  closeModal,
  user,
  showMenu = true,
  onLockButtonClick,
  isAdmin,
  onEdit,
  onPin,
  onUnpin,
  onDelete,
  onShareClick,
  handleApprove,
  handleReject,
  approvalLoading,
  handleReport,
  feedConfig,
  handleHideViewCount,
  handleHideLinkCount,
  updateAnnouncementClientSide,
  readyOnly,
  isLocked,
  onSelectedMemberDetails
}) => {
  // community data
  const communityId = community?._id;
  const [commentCount, setCommentCount] = useState(0);
  const actionBarRef = useRef(null);

  // post data
  const { _id: postId, disabledComments, totalImpressions } = post || {};
  const isPending = post?.status === COMMUNITY_POSTS_STATUS.PENDING;
  const isApproved = post?.status === COMMUNITY_POSTS_STATUS.APPROVED;

  // Post Like Reaction data
  const { isPostLiked, postLikeCount, handleReactionButtonClick } =
    usePostReaction({
      post,
      postType: 'post',
      communityId,
      postId,
      updateAnnouncementClientSide
    });

  // get comment count from selectedComment (which runs on re-render), not the post props which is stale
  useEffect(
    () => setCommentCount(post?.commentCount ?? 0),
    [post?.commentCount]
  );

  const updateAnnouncementInfo = (props) => {
    updateAnnouncementClientSide?.(postId, {
      ...props?.updatedInfo
    });
    setCommentCount(props?.updatedInfo?.commentCount);
    scrollToRef(actionBarRef);
  };

  useEffect(() => {
    if (postId) trackPostViewed(postId, communityId);
  }, [postId, communityId]);

  return (
    <Modal
      open={showPreviewModal}
      showCloseIcon={false}
      onClose={closeModal}
      customCloseIconClass="!fixed !bg-npl-neutral-light-solid-1"
      withOutsideCloseIcon={true}
      customContainerClass={
        '!p-0 !rounded-16 overflow-hidden max-w-[680px]'
      }
      customInnerClass="h-full !p-0">
      <div className="c-CMP-SinglePostPageComponent ">
        {/* Card */}
        <div
          className={`lg:rounded-12 overflow-hidden ${!isApproved && 'pb-16'}`}>
          <PostCardContent
            announcement={post}
            communityLink={community?.link}
            communityName={community?.title}
            onEdit={onEdit}
            isLocked={isLocked}
            showMenu={showMenu}
            onDelete={onDelete}
            fullView={true}
            singlePost={true}
            handleReport={handleReport}
            handleHideViewCount={handleHideViewCount}
            handleHideLinkCount={handleHideLinkCount}
            onPin={onPin}
            onUnpin={onUnpin}
            setAnnouncementToShare={onShareClick}
            isManager={isAdmin}
            showStatus={true}
            user={user}
            onSelectedMemberDetails={onSelectedMemberDetails}
          />
          {isAdmin && isPending && (
            <div className="mb-8 w-full">
              <ApprovalOptions
                announcement={post}
                onReject={handleReject}
                onApprove={handleApprove}
                loading={approvalLoading}
                successCallBack={closeModal}
              />
            </div>
          )}
          {isApproved && (
            <>
              <div
                className=" w-full pt-8 border-npl-separator-alpha"
                ref={actionBarRef}>
                <PostActionBtnsRow
                  postCreationDateString={post?.createdAt}
                  isPostLiked={isPostLiked}
                  onLikeClick={() => {
                    if (isLocked) {
                      onLockButtonClick();
                      return;
                    }
                    handleReactionButtonClick();
                  }}
                  commentCount={commentCount}
                  onShareClick={onShareClick}
                  disabledComments={disabledComments}
                  impressionCount={totalImpressions}
                  isLocked={isLocked}
                  hideViewCount={feedConfig?.hideViewsOnPost}
                  likeCount={
                    feedConfig?.hideLikesOnPost ? 0 : postLikeCount
                  }
                  stretchButtons
                  isCommunityAdmin={true}
                />
              </div>
              {!isLocked && (
                <div className="border-t-1 border-npl-separator-alpha">
                  <CommentSection
                    post={post}
                    readOnly={readyOnly}
                    community={community}
                    updateAnnouncementInfo={updateAnnouncementInfo}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AnnouncementPreviewModal;
